import { useEffect, useState } from 'react';
import Select, { OptionTypeBase } from 'react-select';

interface Item {
    title: string,
    value: string
}

interface Props {
    label: string
    className?: string
    values: Item[]
    selectedValue: string,
    onChange: (value: any) => void
    buttonClass?: string,
    errorKey: string
    errors: { [key: string]: any }
}

export default function DropDown ({ label, className, values, selectedValue, onChange, errors, errorKey }: Props) {
    const [selectedOption, setSelectedOption] = useState<OptionTypeBase | null>(null);

    const options = values.map(val => ({ label: val.title, value: val.value }));

    useEffect(() => {
        const newOption = options.find((o :any) => o.value === selectedValue) || null;
        if (newOption?.value !== selectedOption?.value) {
            setSelectedOption(newOption);
        }
    }, [selectedValue, values]);
    
    return (
        <div className={`form-group ${className}`}>
            <label className={`form-label mr-3 ${errors[errorKey] ? 'is-invalid' : ''}`}>{label}</label>
            <Select
                options={options}
                value={selectedOption}
                onChange={(e) => { onChange(e.value); }}
            />
            {errors[errorKey] && <div className="invalid-feedback">{errors[errorKey].message}</div>}
        </div>
    );
};
