import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ActionP0, ActionP1 } from 'helpers/functionTypes';
import { getJoiValidationSchema, getJoiResolver } from 'helpers/validation';
import Joi from 'joi';
import { clearError, hideLoading, showError, showLoading } from 'redux/ui';
import { useAppDispatch } from 'hooks';
import { RootStoreDispatch } from 'redux/store';
import { client, backend } from 'api';
import { PriceLevelType, PricingLevelModel } from 'api/api';
import DropDown from 'components/elements/form-fields/DropDown';
import { Modal } from 'react-bootstrap';
import { ContactModel } from '../../../api/BackendClient';
import Card from '../../elements/Card';
import LoadingContainer from '../../elements/LoadingContainer';
import Table, { TableProps } from '../../elements/Table';

interface Props {
    initialData: FormFields,
    completeTrigger: ActionP1<ActionP0>
    completeCallback: ActionP1<FormFields>
}

const schema = getJoiValidationSchema({
    name: Joi.any().when('.', { is: Joi.string().min(1), then: Joi.string().max(50) }),
    companyName: Joi.any().when('.', { is: Joi.string().min(1), then: Joi.string().max(50) }),
    firstName: Joi.string().required(),
    lastName: Joi.string().required(),
    email: Joi.string().email({ tlds: { allow: false } }).required(),
    priceLevel: Joi.number().required()
});

export interface FormFields {
    name: string | undefined
    companyName: string | undefined
    firstName: string | undefined
    lastName: string | undefined
    email: string | undefined,
    priceLevel: number | undefined
}

export interface TableData {
    companyName: string | undefined
    firstName: string | undefined
    lastName: string | undefined
    email: string | undefined,
    priceLevel: number | undefined
}

function toTableData (model: ContactModel): TableData {
    return {
        companyName: model.companyName || '',
        firstName: model.firstName || '',
        lastName: model.lastName || '',
        email: model.email || '',
        priceLevel: undefined
    };
}

export default function Prospect ({ initialData, completeTrigger, completeCallback }: Props) {
    const { control, register, handleSubmit, reset, formState: { errors }, setValue } = useForm<FormFields>({
        resolver: getJoiResolver(schema),
        defaultValues: initialData
    });
    const dispatch = useAppDispatch();
    const [priceLevels, setPriceLevels] = useState<PricingLevelModel[]>([]);
    const [contacts, setContacts] = useState<ContactModel[]>([]);
    const [showPipeDrive, setShowPipeDrive] = useState(false);
    const [showAccounts, setShowAccounts] = useState(false);
    const [pipeDriveStatus, setPipeDriveStatus] = useState('');
    
    const showPipeDriveModel = (toggle: boolean) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e?.preventDefault();
        setShowPipeDrive(toggle);
    };

    const showAccountsModel = (toggle: boolean) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e?.preventDefault();
        setShowAccounts(toggle);
    };

    const setFormValues = (data: TableData) => {
        setValue('companyName', data.companyName, { shouldValidate: false });
        setValue('firstName', data.firstName, { shouldValidate: false });
        setValue('lastName', data.lastName, { shouldValidate: false });
        setValue('email', data.email, { shouldValidate: false });
    };

    useEffect(() => {
        const onSubmit = (data: FormFields) => {
            completeCallback(data);
        };

        completeTrigger(() => {
            handleSubmit(onSubmit)();
        });
    });

    useEffect(() => {
        resetForm();
    }, [initialData, priceLevels]);

    useEffect(() => {
        getPriceLevels(dispatch)
            .then(levels => setPriceLevels(levels));
    }, []);

    const props = useMemo<TableProps>(() => ({
        onRowClicked: (data) => {
            setFormValues(data as TableData);
            setShowPipeDrive(false);
            setShowAccounts(false);
        },
        columns: [
            { title: 'Company Name', field: 'companyName' },
            { title: 'First Name', field: 'firstName' },
            { title: 'Last Name', field: 'lastName' },
            { title: 'Email', field: 'email' }
        ]
    }), []);

    const resetForm = () => {
        const fields = { ...initialData };
        if (!fields.priceLevel) {
            fields.priceLevel = priceLevels.find(level => level.levelType === PriceLevelType.Retail && level.level === 0)?.id;
        }
        reset(fields);
    };

    return (
        <form>
            <div className="form-group">
                <label className="form-label">Name</label>
                <input type="text" className={`form-control ${(errors.name) ? 'is-invalid' : ''}`} placeholder="Quote Name" {...register('name')} />
                {errors.name && <div className="invalid-feedback">{errors.name.message}</div>}
            </div>
            <div className="form-group">
                <label className="form-label">Company Name</label>
                <input type="text" className={`form-control ${(errors.companyName) ? 'is-invalid' : ''}`} placeholder="Company Name" {...register('companyName')} />
                {errors.companyName && <div className="invalid-feedback">{errors.companyName.message}</div>}
            </div>
            <div className="form-group">
                <label className="form-label">First Name <i className="text-danger">*</i></label>
                <input type="text" className={`form-control ${(errors.firstName) ? 'is-invalid' : ''}`} placeholder="First Name" {...register('firstName')} />
                {errors.firstName && <div className="invalid-feedback">{errors.firstName.message}</div>}
            </div>
            <div className="form-group">
                <label className="form-label">Last Name <i className="text-danger">*</i></label>
                <input type="text" className={`form-control ${(errors.lastName) ? 'is-invalid' : ''}`} placeholder="Last Name" {...register('lastName')} />
                {errors.lastName && <div className="invalid-feedback">{errors.lastName.message}</div>}
            </div>
            <div className="form-group">
                <label className="form-label">Email address <i className="text-danger">*</i></label>
                <input type="email" className={`form-control ${(errors.email) ? 'is-invalid' : ''}`} placeholder="Email" {...register('email')} />
                {errors.email && <div className="invalid-feedback">{errors.email?.message}</div>}
            </div>
            <Controller
                control={control}
                name="priceLevel"
                render={({ field: { value, onChange } }) => {
                    return (<DropDown
                        label={'Level'}
                        errors={errors}
                        errorKey={'priceLevel'}
                        className={`d-block ${errors.priceLevel ? 'is-invalid' : ''}`}
                        values={priceLevels.map(level => ({ title: level.name || '', value: `${level.id}` }))}
                        selectedValue={`${value}`}
                        onChange={(value) => { onChange(parseInt(value)); }}
                    />);
                }} />
            <div className="top-right-container">
                <button className="btn btn-dark mx-3" onClick={showPipeDriveModel(true)}>Get from Pipedrive { pipeDriveStatus }</button>
                <button className="btn btn-info" onClick={showAccountsModel(true)}>Get from Accounts</button>
            </div>
            <Modal
                size="lg"
                show={showPipeDrive}
                onHide={showPipeDriveModel(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <div className="row">
                    <div className="col-12">
                        <Card title={null}>
                            <LoadingContainer>
                                <SearchBar onSearch={async (searchTerm) => {
                                    if (searchTerm > ' ') {
                                        try {
                                            const contacts = await getContactsByPipedrive(searchTerm);
                                            setContacts(contacts);
                                        } catch (e) {
                                            setPipeDriveStatus(' (not linked)');
                                        }
                                    }
                                }}/>
                                <Table tableProps={props} data={ contacts.map(c => toTableData(c)) } hideSearch={true} />
                            </LoadingContainer>
                        </Card >
                    </div>
                </div>
            </Modal>
            <Modal
                size="lg"
                show={showAccounts}
                onHide={showAccountsModel(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <div className="row">
                    <div className="col-12">
                        <Card title={null}>
                            <LoadingContainer>
                                <SearchBar onSearch={async (searchTerm) => {
                                    if (searchTerm > ' ') {
                                        const contacts = await getContactsByAccounts(searchTerm);
                                        setContacts(contacts);
                                    }
                                }}/>
                                <Table tableProps={props} data={ contacts.map(c => toTableData(c)) } hideSearch={true} />
                            </LoadingContainer>
                        </Card >
                    </div>
                </div>
            </Modal>
        </form>
    );
}

const getPriceLevels = async function (dispatch: RootStoreDispatch) {
    dispatch(showLoading());
    dispatch(clearError());

    let prices: PricingLevelModel[] = [];

    try {
        prices = await client().pricing_PricingLevels();
    } catch (error) {
        dispatch(showError(error));
    }

    prices.sort((a, b) => {
        const val1 = a.name || '';
        const val2 = b.name || '';
        return val1 < val2 ? -1 : (val1 > val2 ? 1 : 0);
    });
    dispatch(hideLoading());
    return prices;
};

const getContactsByPipedrive = async function (searchTerm: string) {
    const contacts = await backend().searchContact(searchTerm);
    return contacts.map((contact):ContactModel => {
        return {
            companyName: contact.companyName,
            email: contact.email,
            firstName: contact.firstName,
            lastName: contact.lastName
        };
    });
};

const getContactsByAccounts = async function (searchTerm: string) {
    const accountModels = await client().account_GetAll(searchTerm);
    return accountModels.map((contact): ContactModel => {
        return {
            email: contact.email,
            firstName: contact.firstName,
            lastName: contact.lastName,
            companyName: contact.name
        };
    });
};

export interface SearchBarProps {
    onSearch: (searchTerm: string) => Promise<void>
}

const SearchBar = ({ onSearch }:SearchBarProps) => {
    const [searchTerm, setSearchTerm] = useState('');
    return (
        <label className="server-side-search-label" data-children-count="1">
            Search: 
            <input 
                value={searchTerm}
                type="search"
                className=" form-control form-control-sm"
                placeholder="" 
                aria-controls="DataTables_Table_0"
                onKeyUp={async (event) => {
                    if (searchTerm.length !== 0 && event.key === 'Enter') {
                        await onSearch(searchTerm);
                    }
                }}
                onChange={(event) => setSearchTerm(event.target.value)}/>
        </label>
    );
};
